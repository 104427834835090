// Import libraries
import {start as turboLinksStart} from "turbolinks";
import {start as activeStorageStart} from "@rails/activestorage";
import {start as ujsStart} from "@rails/ujs";
import {Application, Controller} from "stimulus";
import {definitionsFromContext} from "stimulus/webpack-helpers";

import {loadComponents} from "./components/load_components";
import {initializeFlatpickr} from "./utils/flatpickr_utils";
import {initializeStripe} from "./utils/stripe_utils";
import {fadeOut} from "./utils/animations";
import {initializeModal} from "./utils/modal_utils";
import "trix";

import "channels";
import "@rails/actiontext";
import "@headlessui/react";
import "framer-motion";
import "../stylesheets/application";
import {setupFontAwesome, watchFontAwesome} from "./utils/fontawesome_utils";
import "chartkick/chart.js"

import {AddressAutoComplete} from "./address_autocomplete";
import {DEFAULT_LOCATION, initializeMatchMakingMap} from "./matchmaking";
import {logFirebaseEvent} from "./firebase";

// Stimulus setup
const setupStimulus = () => {
    const application = Application.start();
    const context = require.context("../controllers", true, /\.js$/);
    application.load(definitionsFromContext(context));
};

turboLinksStart();
activeStorageStart();
ujsStart();
setupFontAwesome();
setupStimulus();

// Turbolinks load event
document.addEventListener('turbolinks:load', async function () {
    loadComponents().catch((error) => console.error('Error loading React components:', error));
    initializeFlatpickr();
    initializeStripe();
    initializeModal();
    handleFlashMessages();
    watchFontAwesome();

    initializeGoogleMapsAndMatchmakingMap().catch(console.error);

    // Log the page visit
    logFirebaseEvent('page_view', {page_path: window.location.pathname});

});


// Flash message auto close setup
const handleFlashMessages = () => {
    setTimeout(function () {
        const securityConfirmation = document.getElementById('security-confirmation');
        if (securityConfirmation) {
            fadeOut(securityConfirmation, 1000)
        }
    }, 10000);

    setTimeout(function () {
        const alert = document.getElementById('alert');
        if (alert) {
            fadeOut(alert, 1000)
        }
    }, 10000);
};


async function initializeGoogleMapsAndMatchmakingMap() {
    // Check if the "matchmaking-map" element exists
    if (document.getElementById("matchmaking-map")) {
        // Dynamically load the matchmaking map
        await initializeMatchMakingMap(DEFAULT_LOCATION.lat, DEFAULT_LOCATION.long, DEFAULT_LOCATION.lat);
    }

    // Check if Google Maps API is loaded
    if (typeof google === 'undefined' || !google.maps || !google.maps.places) {
        await loadGoogleMapsAPI();
    }

    AddressAutoComplete();
}

/**
 * Dynamically loads the Google Maps API script and appends it to the document head.
 *
 * @returns {Promise<void>} A promise that resolves when the script is successfully loaded, or rejects if there is an error.
 */
async function loadGoogleMapsAPI() {
    return new Promise((resolve, reject) => {
        const apiKey = document.querySelector('meta[name="google-maps-api-key"]').content;
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
}
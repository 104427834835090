import {Controller} from "stimulus"

export default class extends Controller {
    // use removeEvent target and data-remove-event-name attribute to dispatch a custom event when an association is removed
    static targets = ["add_item", "template", "destroy", "count", "add_more", 'removeEvent', 'destroyDiv']

    // The maximum number of nested fields that can be added
    // This will be set from the data-nested-form-max-count attribute
    max_count = 0;

    connect() {
        // Set the max count of nested fields that can be added by fetching the value from the data-nested-form-max-count attribute
        if (this.add_itemTarget.dataset["nestedFormMaxCount"]) {
            this.max_count = parseInt(this.add_itemTarget.dataset["nestedFormMaxCount"])
        }
    }

    add_association(event) {
        event.preventDefault();

        // replace the child_index TEMPLATE_RECORD with the current timestamp
        const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content)

        if (this.max_count > 0) {

            // Get the count of the nested field items in the add_itemTarget
            let count = this.add_itemTarget.parentElement.querySelectorAll(".nested-fields:not(.hidden)").length
            if (count >= this.max_count) {
                // Hide the add more button
                this.add_itemTarget.classList.add("hidden")
            }

        }
    }

    // TODO: Remove this. There's already a system in place to manage the max count. See connect
    add_max_association(event) {
        if (!this.hasAdd_moreTarget) return;

        if (this.countTargets.length >= 3) {
            this.add_moreTarget.classList.add("hidden")
        } else {
            this.add_moreTarget.classList.remove("hidden")
        }
    }

    remove_association(event) {
        event.preventDefault()
        const removeEventName = this.hasRemoveEventTarget ? this.removeEventTarget.dataset["removeEventName"] : null
        const item = event.target.closest(".nested-fields")
        item.classList.remove('scale-100')
        item.classList.add('scale-0')

        setTimeout(function () {
            // Destroy entire div or just "destroyContent" div
            const destroyContent = item.getElementsByClassName("nested-fields-content")
            if (destroyContent.length > 0) {
                // Set the value of the _destroy attribute to true
                const destroyAttr = item.getElementsByClassName("nested-fields-destroy")
                destroyAttr[0].value = true;
                // Remove the item from the DOM
                destroyContent[0].remove();
            } else {
                item.remove();
            }
            // Dispatch a custom event to notify other controllers that an association has been removed
            if(removeEventName) {
                const eventToDispatch = new CustomEvent(removeEventName);
                document.dispatchEvent(eventToDispatch);
            }
        }, 300);
    }

    remove_association_and_destroy(event) {
        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        item.classList.add("hidden");

        let destroyAttr = item.getElementsByClassName("nested-fields-destroy")
        destroyAttr[0].value = true;

        if (this.max_count > 0) {

            // Get the count of the nested field items in the add_itemTarget
            let count = this.add_itemTarget.parentElement.querySelectorAll(".nested-fields:not(.hidden)").length
            if (count < this.max_count) {
                // Hide the add more button
                this.add_itemTarget.classList.remove("hidden")
            }

        }
    }
}
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {
    faClipboard,
    faPlus,
    faList,
    faEdit,
    faInfo,
    faInfoCircle,
    faTimes,
    faChevronUp,
    faChevronDown,
    faFilter,
    faEnvelope,
    faPhoneAlt,
    faMinus,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faArrowRight,
    faBriefcase,
    faChartBar,
    faFileInvoice,
    faPiggyBank,
    faClock,
    faPercent,
    faExclamationTriangle,
    faFileContract,
    faDollarSign,
    faRotateLeft,
    faCircleCheck,
    faDownload,
    faPlusCircle,
    faPerson,
    faSearch,
    faSpinner,
    faTimesCircle,
    faBook,
    faPhone,
    faTrash,
    faQuestionCircle,
    faLongArrowRight,
    faLongArrowLeft,
    faHandshakeSimple,
    faPersonCircleCheck,
    faTriangleExclamation,
    faRotate,
    faLocationCrosshairs,
    faCheck,
    faCalendar,
    faPeopleRoof,
    faCreditCard,
    faBuildingColumns,
    faChevronRight,
    faUser,
    faMagnifyingGlass,
    faSort,
    faSortAsc,
    faSortDesc,
    faFileLines,
    faNotesMedical,
    faSquareCheck,
    faUserGroup,
    faFileSignature,
    faLock,
    faAt,
    faStar,
    faUpload,
    faUtensils,
    faLanguage,
    faPersonFallingBurst,
    faEye
} from "@fortawesome/free-solid-svg-icons";

export const setupFontAwesome = () => {
    library.add(faPersonFallingBurst, faEye, faUpload, faUtensils, faLanguage, faStar, faSort, faSortAsc, faSortDesc, faMagnifyingGlass, faAt, faLock, faFileSignature, faUserGroup, faSquareCheck, faNotesMedical, faFileLines, faBriefcase, faUser, faChevronRight, faBuildingColumns, faCreditCard, faInfoCircle, faPeopleRoof, faCheck, faLocationCrosshairs, faRotate, faPersonCircleCheck, faTriangleExclamation, faHandshakeSimple, faLongArrowLeft, faLongArrowRight, faQuestionCircle, faTrash, faPhone, faSpinner, faTimesCircle, faBook, faSearch, faPerson, faPlusCircle, faClipboard, faCircleCheck, faRotateLeft, faDollarSign, faFileContract, faExclamationTriangle, faPercent, faPiggyBank, faClock, faPlus, faEdit, faInfo, faTimes, faChevronUp, faChevronDown, faList, faFilter, faEnvelope, faPlus, faMinus, faPhoneAlt, faArrowDown, faArrowUp, faArrowLeft, faArrowRight, faBriefcase, faChartBar, faFileInvoice, faDownload, faCalendar);
}

export const watchFontAwesome = () => {
    dom.watch();
}
/**
 * Initializes the Flatpickr plugin for date and time picker.
 *
 * @param {Function} changeTimeZone - The function that changes the timezone of the date.
 *
 * @return {void}
 */
export function initializeFlatpickr() {
    const flatpickrDivs = document.querySelectorAll('[id=flatpickr]')
    const timeZone = document.getElementById('time_zone')

    if (flatpickrDivs.length > 0) {

        loadFlatpickr().then((flatpickr) => {

            // flatpickr

            flatpickrDivs.forEach(flatpickDiv => {
                // default date
                let defaultDate = null
                if (flatpickDiv.dataset.defaultdate?.length > 0) defaultDate = new Date(flatpickDiv.dataset.defaultdate)
                // change the default browser timezone to the app time zone
                if (timeZone && defaultDate) defaultDate = changeTimeZone(defaultDate, `Australia/${timeZone.innerText}`)
                // min date
                let minDate = null
                if (flatpickDiv.dataset.mindate) minDate = new Date().fp_incr(flatpickDiv.dataset.mindate)
                if (flatpickDiv.dataset.mindate == 0) minDate = new Date()

                // max date
                let maxDate = null
                if (flatpickDiv.dataset.maxdate) maxDate = new Date(flatpickDiv.dataset.maxdate)

                    flatpickDiv.querySelectorAll("[data-behavior='flatpickr-date']").forEach (datePickr => {
    
                    // Settings for date picker
                    flatpickr(datePickr, {
                        altFormat: "D d F Y",
                        dateFormat: "D d F Y",
                        minDate: minDate,
                        defaultDate: defaultDate,
                        maxDate: maxDate,
                        allowInput: true // Allows HTML5 validations to run. Investigate this when we have better FE validation strategy
                    })
                })

                // Settings for time picker
                flatpickDiv.querySelectorAll("[data-behavior='flatpickr-time']").forEach (timePickr => {

                    flatpickr(timePickr, {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "h:i K",
                        defaultDate: defaultDate,
                        allowInput: true
                    })
                })

                 // Settings for datetime picker
                 flatpickDiv.querySelectorAll("[data-behavior='flatpickr-datetime']").forEach (datetimePickr => {
                    initializeFlatpickrDateTime(datetimePickr)
                })

                // Add autocomplete off to all flatpickr fields
                // Chrome will override the flatpickr with their autocomplete
                flatpickDiv.setAttribute('autocomplete', 'off')

            })
        })
            .catch((error) => {
                console.error('Error loading flatpickr:', error);
            });


    }
}

export function initializeFlatpickrDateTime(datetimePickr) {
    // if (datetimePickr.readOnly) { return; };

    let defaultDate = findDefaultDate(datetimePickr)
    let localMinDate = findMinDateTime(datetimePickr)

    loadFlatpickr().then((flatpickr) => {
        flatpickr(datetimePickr, {
            enableTime: true,
            // noCalendar: true,
            dateFormat: "D d M y, h:i K", // "%a %d %b %y, %I:%M %p"
            defaultDate: defaultDate,
            minDate: localMinDate,
            minuteIncrement: 5,
            defaultMinute: 0,
            allowInput: true
        })
    })
}

/**
 * Loads the Flatpickr library asynchronously and returns a promise that resolves to the Flatpickr module.
 *
 * @returns {Promise<unknown>} - A promise that resolves to the Flatpickr module.
 */
async function loadFlatpickr() {
    return new Promise(async (resolve, reject) => {
        try {
            const {default: flatpickr} = await import("flatpickr");
            resolve(flatpickr)
        } catch (error) {
            reject(error.message)
        }
    })
}

// Find value of mindatetime attribute
function findMinDateTime(datetimePickr) {
    if (datetimePickr.dataset.minDateTime) {
        return new Date(datetimePickr.dataset.minDateTime);
    }
}

// Find value of defaultDate attribute
function findDefaultDate(datetimePickr) {
    if (datetimePickr.dataset.defaultDate) {
        return new Date().fp_incr(datetimePickr.dataset.defaultDate)
    } 
}

// Change timezone function
function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            }),
        );
    }

    return new Date(
        date.toLocaleString('en-US', {
            timeZone,
        }),
    );
}
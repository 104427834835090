import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "input"];

  connect() {
    // Use data parameter "disable-default" to prevent default execustion on connect
    if (!this.data.get("disableDefault")) this.showButtonHideInput()
  }

  toggle() {
    if (this.inputTarget.classList.contains("hidden")) {
      this.showInputHideButton()
    } else {
      this.showButtonHideInput()
    }
  }

  untoggle() {
    this.showButtonHideInput()
  }

  showInputHideButton() {
    if (this.hasInputTarget) {
      this.inputTarget.classList.remove("hidden");
      this.inputTarget.classList.add("block");
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove("block");
      this.buttonTarget.classList.remove("btn");
      this.buttonTarget.classList.add("hidden");
    }
  }

  showButtonHideInput() {
    if (this.hasInputTarget) {
      this.inputTarget.classList.add("hidden");
      this.inputTarget.classList.remove("block");
    }
    if (this.hasButtonTarget) {
      // this.buttonTarget.classList.add("block");
      this.buttonTarget.classList.remove("hidden");
    }
  }
}
